import { call, put } from "redux-saga/effects";
import { dkkApi } from "../../services/api/index";
import { actionCondition } from "../condition/conditionSlice";
import { actionRegisterExam } from "./registerExamSlice";

function* handleRegister({ payload, type }) {
  try {
    yield put(actionCondition.setErrMessage(null));
    yield put(actionCondition.setLoading(true));
    const register = yield call(dkkApi.register, payload);
    if (!register || !register.Data) {
      yield put(actionCondition.setErrMessage(register?.Param?.Messages));
      yield put(actionCondition.setLoading(false));
      return;
    }
    yield put(actionRegisterExam.setInfoRegisterDetail(register?.Data));
    yield put(actionCondition.setLoading(false));
  } catch (error) {
    console.log(error);
  }
}
export { handleRegister };
