import { createSlice } from "@reduxjs/toolkit";

const roomSlice = createSlice({
  name: "room",
  initialState: {
    rooms: [],
  },
  reducers: {
    setRoom: (state, action) => ({ ...state, rooms: action.payload }),
  },
});
export const { setRoom } = roomSlice.actions;
export default roomSlice.reducer;
