import { createSlice } from "@reduxjs/toolkit";
const registerExamSlice = createSlice({
  name: "register",
  initialState: {
    step: 1,
    registration_infomation: null,
    provinceExam: null,
    hospitalExam: null,
    mediOrgExam: null,
    examRegisTime: null,
    patientTypeExam: null,
    serviceExam: null,
    roomExam: null,
    symptom: "",
    heinCard: null,
    infoRegisterDetail: null,
  },
  reducers: {
    setNextStep: (state, action) => ({ ...state, step: state.step + 1 }),
    setPreviousStep: (state, action) => ({ ...state, step: state.step - 1 }),
    setDefaultStep: (state, action) => ({ ...state, step: 1 }),
    setStep: (state, action) => ({ ...state, step: action.payload }),
    setRegistrationInfomation: (state, action) => ({
      ...state,
      registration_infomation: action.payload,
    }),
    setProvinceExam: (state, action) => ({
      ...state,
      provinceExam: action.payload,
    }),
    setMediOrgExam: (state, action) => ({
      ...state,
      mediOrgExam: action.payload,
    }),
    setHospitalExam: (state, action) => ({
      ...state,
      hospitalExam: action.payload,
    }),
    setExamRegisTime: (state, action) => ({
      ...state,
      examRegisTime: action.payload,
    }),
    setPatientTypeExam: (state, action) => ({
      ...state,
      patientTypeExam: action.payload.patientTypeExam,
      serviceExam: action.payload.serviceExam,
      roomExam: action.payload.roomExam,
      symptom: action.payload.symptom,
    }),
    setHeinCard: (state, action) => ({
      ...state,
      heinCard: action.payload,
    }),
    setInfoRegisterDetail: (state, action) => ({
      ...state,
      infoRegisterDetail: action.payload,
    }),
    register() {},
    clearInfo() {},
    nextOrder() {},
  },
});
export const actionRegisterExam = {
  setNextStep: registerExamSlice.actions.setNextStep,
  setPreviousStep: registerExamSlice.actions.setPreviousStep,
  setDefaultStep: registerExamSlice.actions.setDefaultStep,
  setStep: registerExamSlice.actions.setStep,
  setRegistrationInfomation:
    registerExamSlice.actions.setRegistrationInfomation,
  setProvinceExam: registerExamSlice.actions.setProvinceExam,
  setMediOrgExam: registerExamSlice.actions.setMediOrgExam,
  setExamRegisTime: registerExamSlice.actions.setExamRegisTime,
  setHospitalExam: registerExamSlice.actions.setHospitalExam,
  setPatientTypeExam: registerExamSlice.actions.setPatientTypeExam,
  setHeinCard: registerExamSlice.actions.setHeinCard,
  register: registerExamSlice.actions.register,
  clearInfo: registerExamSlice.actions.clearInfo,
  setInfoRegisterDetail: registerExamSlice.actions.setInfoRegisterDetail,
  nextOrder: registerExamSlice.actions.nextOrder,
};
export default registerExamSlice.reducer;
