import { createSlice } from "@reduxjs/toolkit";

const patientTypelSlice = createSlice({
  name: "hospital",
  initialState: {
    patientTypes: [],
  },
  reducers: {
    setPatientType: (state, action) => ({
      ...state,
      patientTypes: action.payload,
    }),
  },
});
export const { setPatientType } = patientTypelSlice.actions;
export default patientTypelSlice.reducer;
