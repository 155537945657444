import { call, put } from "redux-saga/effects";
import { cosApi, dkkApi } from "../../services/api/index";
import { apiParamCommon } from "../../services/model/apiParamCommon";
import HeinCardModel from "../../services/model/HeinCardModel";
import { patientSdo } from "../../services/model/patientSdo";
import { Convert } from "../../utils/convert/Convert";
import { actionCondition } from "./conditionSlice";

function* handleFindPatient({ payload, type }) {
  try {
    yield put(actionCondition.setLoading(true));
    const user = yield call(cosApi.getPatient, payload);
    if (!user || !user.Data) {
      yield put(actionCondition.setErrMessage(`Không tìm thấy bệnh nhân`));
      yield put(actionCondition.setLoading(false));
      return;
    }
    if (
      user.Data.Dob &&
      user.Data.Bhyts &&
      user.Data.GenderName &&
      user.Data.FullName
    ) {
      const heinCard = yield call(
        dkkApi.checkHeinCard,
        apiParamCommon({
          Dob: user.Data.Dob,
          Gender: user.Data.GenderName,
          HeinCardNumber: user.Data.Bhyts[user.Data.Bhyts.length - 1]?.BhytNumber,
          PatientName: user.Data.FullName,
        })
      );
      if (heinCard)
        yield put(actionCondition.setHeinCard(HeinCardModel(heinCard?.Data)));
    }
    yield put(actionCondition.setPatientInfomation(patientSdo(user.Data)));
    yield put(actionCondition.setLoading(false));
  } catch (error) {
    console.log(error);
  }
}

function* handleGetHeinCard({ payload, type }) {
  try {
    yield put(actionCondition.setLoading(true));
    yield put(actionCondition.setErrMessage(null));
    const heinCard = yield call(
      dkkApi.checkHeinCard,
      apiParamCommon({
        Dob: Convert.convertDateStringToLong(payload?.Dob),
        Gender: payload?.GenderName,
        HeinCardNumber: payload?.BhytNumber,
        PatientName: payload?.FullName,
      })
    );
    if (!heinCard || !heinCard.Data) {
      yield put(actionCondition.setErrMessage(heinCard?.Param?.Messages));
      yield put(actionCondition.setLoading(false));
      return;
    }
    // yield put(setRegistrationInfomation({ ...payload }));
    yield put(actionCondition.setHeinCard(HeinCardModel(heinCard?.Data)));
    yield put(actionCondition.setLoading(false));
    // yield put(setNextStep());
  } catch (error) {
    console.log(error);
  }
}

export { handleFindPatient, handleGetHeinCard };
