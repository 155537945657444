import { lazy } from "react";
import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

const HomePage = lazy(() => import("./components/pages/HomePage"));
const Main = lazy(() => import("./components/pages/Main"));
const RegisterExamPage = lazy(() =>
  import("./components/pages/RegisterExamPage")
);
const RegisterSuccess = lazy(() =>
  import("./components/pages/RegisterSuccess")
);

function App() {

  return (
    <div className="container mx-auto">
      <Suspense>
        <Routes>
          <Route element={<Main></Main>}>
            <Route path="/" element={<HomePage />} />
            <Route path="/register" element={<RegisterExamPage />} />
            <Route path="/register/success" element={<RegisterSuccess />} />
          </Route>
          <Route path="*" element={<>Not Found</>}></Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
