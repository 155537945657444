import { Convert } from "../../utils/convert/Convert";

export const patientSdo = (props) => ({
  ...props,
  FullName: props?.FullName || "",
  GenderName: props?.GenderName || "",
  Dob: props?.Dob ? Convert.convertLongToDateString(props.Dob) : "",
  Phone: props?.Phone || "",
  BhytNumber: props?.Bhyts?.[props?.Bhyts?.length - 1]?.BhytNumber || "",
  ToTime: props?.Bhyts?.[props?.Bhyts?.length - 1]?.ToTime
    ? Convert.convertLongToDateString(props.Bhyts?.[props?.Bhyts?.length - 1]?.ToTime)
    : "",
  ProvinceName: props?.ProvinceName || "",
  DistrictName: props?.DistrictName || "",
  CommuneName: props?.CommuneName || "",
  FullAddress: props?.FullAddress || "",
});
