import apiClient from "../axios";
import { GET_BY_BASIC_INFO, DO_MAIN_COS } from "./url";

const cosApi = {
  getPatient: (params) => {
    const url = DO_MAIN_COS + GET_BY_BASIC_INFO;
    return apiClient.get(url, { params });
  },
};

export default cosApi;
