import { createSlice } from "@reduxjs/toolkit";
import { MEDI_ORG_DATA_STORAGE } from "../../utils/constant";

const MediOrgDataStorage = localStorage.getItem(MEDI_ORG_DATA_STORAGE);
const mediOrgs = MediOrgDataStorage && JSON.parse(MediOrgDataStorage);

const mediOrgSlice = createSlice({
  name: "mediOrg",
  initialState: {
    mediOrgs,
  },
  reducers: {
    setMediOrg: (state, action) => {
      return { ...state, mediOrgs: action.payload };
    },
  },
});
export const { setMediOrg } = mediOrgSlice.actions;
export default mediOrgSlice.reducer;
