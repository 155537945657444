import apiClient from "../axios";
import {
  GET_MEDI_ORG,
  GET_HOSPITAL,
  GET_PATIENT_TYPE,
  GET_DKK_PTRS,
  REGISTER,
  CHECK_HEIN_CARD,
  DO_MAIN_DKK,
} from "./url";

const dkkApi = {
  getMediOrg: (params) => {
    const url = DO_MAIN_DKK + GET_MEDI_ORG;
    return apiClient.get(url, { params });
  },
  getHospital: (params) => {
    const url = DO_MAIN_DKK + GET_HOSPITAL;
    return apiClient.get(url, { params });
  },
  getPatientType: (params) => {
    const url = DO_MAIN_DKK + GET_PATIENT_TYPE;
    return apiClient.get(url, { params });
  },
  getDkkPtrs: (params) => {
    const url = DO_MAIN_DKK + GET_DKK_PTRS;
    return apiClient.get(url, { params });
  },
  checkHeinCard: (payload) => {
    const url = DO_MAIN_DKK + CHECK_HEIN_CARD;
    return apiClient.post(url, payload);
  },
  register: (payload) => {
    const url = DO_MAIN_DKK + REGISTER;
    return apiClient.post(url, payload);
  },
};

export default dkkApi;
