import { createSlice } from "@reduxjs/toolkit";
import { dataOptions } from "../../utils/constant";
const condidionSlice = createSlice({
  name: "condition",
  initialState: {
    isHasHealthRecord: true,
    options: dataOptions,
    optionToSearch: null,
    infoToSearch: null,
    loading: false,
    errMessage: null,
    patientInfomation: null,
    heinCard: null,
  },
  reducers: {
    setHasHealthRecord: (state, action) => ({
      ...state,
      isHasHealthRecord: true,
    }),
    setNotHasHealthRecord: (state, action) => ({
      ...state,
      isHasHealthRecord: false,
    }),
    setOptionToSearch: (state, action) => ({
      ...state,
      optionToSearch: action.payload,
    }),
    setLoading: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setErrMessage: (state, action) => ({
      ...state,
      errMessage: action.payload,
    }),
    setPatientInfomation: (state, action) => ({
      ...state,
      patientInfomation: action.payload,
    }),
    setHeinCard: (state, action) => ({
      ...state,
      heinCard: action.payload,
    }),
    getPatientInfomation() {},
    getHeinCard() {},
  },
});
export const actionCondition = {
  setHasHealthRecord: condidionSlice.actions.setHasHealthRecord,
  setNotHasHealthRecord: condidionSlice.actions.setNotHasHealthRecord,
  setOptionToSearch: condidionSlice.actions.setOptionToSearch,
  setLoading: condidionSlice.actions.setLoading,
  setErrMessage: condidionSlice.actions.setErrMessage,
  setPatientInfomation: condidionSlice.actions.setPatientInfomation,
  getPatientInfomation: condidionSlice.actions.getPatientInfomation,
  getHeinCard: condidionSlice.actions.getHeinCard,
  setHeinCard: condidionSlice.actions.setHeinCard,
};
export default condidionSlice.reducer;
