import { combineReducers } from "@reduxjs/toolkit";
import communeSlice from "./commune/communeSlice";
import conditionSlice, { actionCondition } from "./condition/conditionSlice";
import districtSlice from "./districts/districtSlice";
import hospitalSlice from "./hospital/hospitalSlice";
import mediOrgSlice from "./mediOrg/mediOrgSlice";
import patientTypeSlice from "./patientType/patientTypeSlice";
import provinceSlice from "./province/provinceSlice";
import registerExamSlice, {
  actionRegisterExam,
} from "./register/registerExamSlice";
import roomSlice from "./room/roomSlice";
import serviceSlice from "./service/serviceSlice";

const appReducer = combineReducers({
  condition: conditionSlice,
  register: registerExamSlice,
  mediOrg: mediOrgSlice,
  province: provinceSlice,
  district: districtSlice,
  commune: communeSlice,
  hospital: hospitalSlice,
  patientType: patientTypeSlice,
  service: serviceSlice,
  room: roomSlice,
});
export const reducer = (state, action) => {
  switch (action.type) {
    case actionRegisterExam.clearInfo()?.type:
      return appReducer(undefined, action);
    case actionRegisterExam.nextOrder()?.type:
      return appReducer({ ...state, register: undefined }, action);
    default:
      return appReducer(state, action);
  }
};
