import { createSlice } from "@reduxjs/toolkit";

const hospitalSlice = createSlice({
  name: "hospital",
  initialState: {
    hospitals:[],
  },
  reducers: {
    setHospital: (state, action) => ({ ...state, hospitals: action.payload }),
  },
});
export const { setHospital } = hospitalSlice.actions;
export default hospitalSlice.reducer;
