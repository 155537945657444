export const DO_MAIN_COS = window.DO_MAIN_COS;
export const DO_MAIN_ACS = window.DO_MAIN_ACS;
export const DO_MAIN_THE = window.DO_MAIN_THE;
export const DO_MAIN_DKK = window.DO_MAIN_DKK;
export const DO_MAIN_SDA = window.DO_MAIN_SDA;
export const DO_MAIN_CPS = window.DO_MAIN_CPS;

export const GET_BY_BASIC_INFO = "api/CosPeople/GetByBasicInfo";
export const GET_PROVINCE = "api/SdaProvince/Get";
export const GET_DISTRICT = "api/SdaDistrict/Get";
export const GET_COMMUNE = "api/SdaCommune/Get";
export const GET_MEDI_ORG = "api/DkkHospital/GetMediOrg";
export const GET_HOSPITAL = "api/DkkHospital/GetView";
export const GET_PATIENT_TYPE = "api/DkkPatientType/Get";
export const GET_DKK_PTRS = "api/DkkPtrs/GetView";
export const REGISTER = "api/DkkRegister/CreateWithOtp";
export const REQUIRED_ONLY_WITH_TEMPLATE_CODE =
  "api/AcsOtp/RequiredOnlyWithTemplateCode";
export const CHECK_HEIN_CARD = "api/DkkRegister/CheckHeinCard";
export const CREATE_CPS_REPORT = "api/CpsReport/Create";