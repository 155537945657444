export const SO_THE_KHAM_CHUA_BENH = "Số thẻ khám chữa bệnh";
export const SO_DIEN_THOAI = "Số điện thoại";
export const SO_CMND_CCCD = "Số CMND/CCCD";
export const MUOI_SO_CUOI_BHYT = "10 số cuối thẻ BHYT";

export const TRUONG_DU_LIEU_BAT_BUOC = "Dữ liệu bắt buộc";
export const VALID_NUMBER_STR = "Dữ liệu phải là số";

export const MEDI_ORG_DATA_STORAGE = "MediOrgDataStorage";
export const PROVINCE_DATA_STORAGE = "ProvinceDataStorage";
export const DISTRICT_DATA_STORAGE = "DistrictDataStorage";
export const COMMUNE_DATA_STORAGE = "CommuneDataStorage";

export const TYPE_PROVINCE = "PROVINCE_NAME";
export const TYPE_DISTRICT = "DISTRICT_NAME";
export const TYPE_COMMUNE = "COMMUNE_NAME";
export const TYPE_MEDI_ORG = "HeinMediOrgName";
export const TYPE_HOSPITAL = "HOSPITAL_NAME";

export const TYPE_PATIENT_TYPE = "PATIENT_TYPE_NAME";
export const TYPE_SERVICE = "SERVICE_NAME";
export const TYPE_ROOM = "ROOM_NAME";

export const dataOptions = [
  { id: 1, option_name: SO_THE_KHAM_CHUA_BENH },
  { id: 2, option_name: SO_DIEN_THOAI },
  { id: 3, option_name: SO_CMND_CCCD },
  { id: 4, option_name: MUOI_SO_CUOI_BHYT },
];

export const GENDER_NAM = "Nam";
export const GENDER_NU = "Nữ";
export const GENDER_KXD = "Không xác định";

export const genders = [
  { id: 2, gender_name: GENDER_NAM },
  { id: 1, gender_name: GENDER_NU },
  { id: 3, gender_name: GENDER_KXD },
];
export const WEEKDAYS = ["T2", "T3", "T4", "T5", "T6", "T7", "CN"];

export const STEPs = [
  { id: 1, name: "Bước 1", detail: "Thông tin người đăng ký khám" },
  { id: 2, name: "Bước 2", detail: "Chọn sơ sở khám chữa bệnh" },
  { id: 3, name: "Bước 3", detail: "Chọn ngày khám" },
  { id: 4, name: "Bước 4", detail: "Chọn đối tượng khám" },
  { id: 5, name: "Bước 5", detail: "Xác nhận đăng ký khám" },
];
