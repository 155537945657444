const HeinCardModel = (props) => {
  return {
    FullAddress: props?.diaChi,
    GenderName: props?.gioiTinh,
    FromTime: props?.gtTheTuMoi || props?.gtTheTu,
    ToTime: props?.gtTheDenMoi || props?.gtTheDen,
    FullName: props?.hoTen,
    MediOrgCode: props?.maDKBDMoi,
    BhytNumber: props?.maThe,
    Dob: props?.ngaySinh,
    IsHasNotDayDob: (props?.ngaySinh && props?.ngaySinh !== null && props?.ngaySinh.length == 4) ? 1 : 0,
  };
};

export default HeinCardModel;
