export const Convert = {
  convertDateStringToLong: (input) => {
    let output = "";
    if (input) {
      var arr = input.replaceAll(" ", "").split(/[.\-=/_]/);
      if (arr?.length === 3) output = +`${arr[2]}${arr[1]}${arr[0]}000000`;
    }
    return output;
  },
  convertDateStringToLongYear: (input) => {
    let output = "";
    if (input) {
      var arr = input.replaceAll(" ", "").split(/[.\-=/_]/);
      if (arr?.length === 3) output = +`${arr[2]}`;
    }
    return output;
  },
  convertLongToDateString: (input) =>
    input
      ? `${input.toString().substr(6, 2)} - ${input
          .toString()
          .substr(4, 2)} - ${input.toString().substr(0, 4)}`
      : "",
  getFirstNameFromName: (name = "") => {
    const arrName = name.split(" ");
    return arrName ? arrName[arrName.length - 1] : "";
  },
  getLastNameFromName: (name = "") => {
    const arrName = name.split(" ");
    arrName.splice(arrName.length - 1, 1);
    return arrName ? arrName.join(" ") : "";
  },
  convertStringToDate: (dateStr = "") => {
    const arrDate = dateStr.replaceAll(" ", "").split(/[.\-=/_]/);
    return arrDate && new Date(arrDate[2], arrDate[1] - 1, arrDate[0]);
  },
  convertTimeWithZero: (time) => {
    return +time < 10 ? `0${time}` : time;
  },
};
