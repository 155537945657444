import { createSlice } from "@reduxjs/toolkit";
import { PROVINCE_DATA_STORAGE } from "../../utils/constant";
const ProvinceDataStorage = localStorage.getItem(PROVINCE_DATA_STORAGE);
const provinces = ProvinceDataStorage && JSON.parse(ProvinceDataStorage);

const provinceSlice = createSlice({
  name: "province",
  initialState: {
    provinces,
  },
  reducers: {
    setProvince: (state, action) => ({ ...state, provinces: action.payload }),
  },
});
export const { setProvince } = provinceSlice.actions;
export default provinceSlice.reducer;
