import apiClient from "../axios";
import { GET_PROVINCE, DO_MAIN_SDA, GET_DISTRICT, GET_COMMUNE } from "./url";

const sdaApi = {
  getProvince: (params) => {
    const url = DO_MAIN_SDA + GET_PROVINCE;
    return apiClient.get(url, { params });
  },
  getDistrict: (params) => {
    const url = DO_MAIN_SDA + GET_DISTRICT;
    return apiClient.get(url, { params });
  },
  getCommune: (params) => {
    const url = DO_MAIN_SDA + GET_COMMUNE;
    return apiClient.get(url, { params });
  },
};

export default sdaApi;
