import { createSlice } from "@reduxjs/toolkit";
const districtSlice = createSlice({
  name: "district",
  initialState: {
    districts: null,
  },
  reducers: {
    setDistrict: (state, action) => ({ ...state, districts: action.payload }),
  },
});
export const { setDistrict } = districtSlice.actions;
export default districtSlice.reducer;
