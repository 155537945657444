import apiClient from "../axios";
import { REQUIRED_ONLY_WITH_TEMPLATE_CODE, DO_MAIN_ACS } from "./url";

const acsApi = {
  registerWithOtp: (payload) => {
    const url = DO_MAIN_ACS + REQUIRED_ONLY_WITH_TEMPLATE_CODE;
    return apiClient.post(url, payload);
  },
};

export default acsApi;
