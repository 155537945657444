import apiClient from "../axios";
import { CREATE_CPS_REPORT, DO_MAIN_CPS } from "./url";

const cpsApi = {
  createCpsReport: (payload) => {
    const url = DO_MAIN_CPS + CREATE_CPS_REPORT;
    return apiClient.post(url, payload);
  },
};

export default cpsApi;
