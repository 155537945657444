import { createSlice } from "@reduxjs/toolkit";

const districtSlice = createSlice({
  name: "commune",
  initialState: {
    communes: null,
  },
  reducers: {
    setCommune: (state, action) => ({ ...state, communes: action.payload }),
  },
});
export const { setCommune } = districtSlice.actions;
export default districtSlice.reducer;
