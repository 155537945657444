import { createSlice } from "@reduxjs/toolkit";

const serviceSlice = createSlice({
  name: "service",
  initialState: {
    services: [],
  },
  reducers: {
    setService: (state, action) => ({ ...state, services: action.payload }),
  },
});
export const { setService } = serviceSlice.actions;
export default serviceSlice.reducer;
